<template>
    <div>
        <header-portrate />
    <div class="m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" style="margin-top:60px !important;" />
        <v-row class="m-2">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="tablerows"
                    width="100%"
                    id="MainTable"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td style="width:10%;" class="text-center">{{ row.item.itemcode }}</td>
                            <td style="width:60%;" class="text-start" nowrap>{{ row.item.name }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.price }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.qty }}</td>
                            <td style="width:10%;" class="text-center">{{ row.item.ftotal }}</td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <vue-snotify></vue-snotify>
    </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import Footer from '@/components/Footer.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue'

export default{
  components: { breadCrumbs, TabsComp,Footer,HeaderPortrate },
    name: 'Products',
    data() {
        return {
            active_tab:3,
            items: [
                // {text: this.$store.state.hometitle, disabled: false, to: '/home'},
                // {text: 'المخزون', disabled: false, to: '/inventory/products'},
                {text: 'الأصناف المنتهية', disabled: true, to: '/inventory/finished'},
            ],
            
            headers: [
                {
                    text: 'رقم القطعة',
                    align: 'center',
                    filterable: false,
                    value: 'itemcode',sortable: false,
                },
                {
                    text: 'الاسم',
                    align: 'center',
                    filterable: false,
                    value: 'name',sortable: false,
                },
                {
                    text: 'السعر',
                    align: 'center',
                    filterable: false,
                    value: 'price',sortable: false,
                },
                {
                    text: 'الكمية المتوفرة',
                    align: 'center',
                    filterable: false,
                    value: 'qty',sortable: false,
                },
                {
                    text: 'أجمالي السعر',
                    align: 'center',
                    filterable: false,
                    value: 'ftotal',sortable: false,
                },
            ],
            tablerows: [

            ],
            
        }
    },
    created() {
        this.getProducts();
    },
    methods: {
        calcTo () {
            const xy = this.$store.state.products;
            // this.tablerows = this.$store.state.products;
            for(let i=0;i<xy.length;i++){
                const ss = parseFloat(xy[i].price) * parseFloat(xy[i].qty);
                const y = {
                    itemcode: xy[i].itemcode,
                    name: xy[i].name,
                    qty: xy[i].qty,
                    price: xy[i].price,
                    ftotal: this.$RoundNum(ss)
                };
                if(ss == 0){
                    this.tablerows.push(y);
                }
            }
        },
        getProducts(){
          const post = new FormData();
          post.append('type','getProducts');
          post.append('auth',this.$cookies.get(this.$COOKIEPhase));
          post.append('data[ids]','0');
            axios.post(this.$SAMCOTEC.r_path,post)
          .then((response) => {
              const res = response.data;
              if(res.results.data.length > 0){
                  this.$store.state.products = res.results.data;                
              }
              this.calcTo ();
          });
      },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function() {
            return [
                {
                    index:0,name:this.lang.vendors,href:'/inventory/vendors',class:'mytab3',
                },
               {
                    index:0,name:this.lang.purchase,href:'/inventory/purchase',class:'mytab5',
                },
                {
                    index:1,name:this.lang.product_categories,href:'/inventory/products-cats', class: 'mytab3'
                },
                {
                    index:2,name:this.lang.products,href:'/inventory/products', class: 'mytab1'
                },
                {
                    index:3,name:' الاكثر مبيعا',href:'/inventory/best-seller', class: 'mytab3'
                },
                {
                    index:4,name:' الاقل مبيعا',href:'/inventory/lowest-selling', class: 'mytab3'
                },
                {
                    index:5,name:' المنتهية',href:'/inventory/finished', class: 'mytab'
                },
                {
                    index:5,name:'حركة صنف',href:'/inventory/itemtrans', class: 'mytab4'
                }
            ]
        }
    },
}
</script>